@import 'styles/variables';
.wrapper {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $border-color;
    h1{
        font-weight: normal;
    }
    svg {
        margin-right: 1rem;
      path{
        fill: $color-warning;
      }  
    }
  }
}
