.border-none {
  border: none !important;
}
.border-b-none {
  border-bottom: none !important;
}
.border-t-none {
  border-top: none !important;
}
.border-b {
  border-bottom: 1px solid  $border-color;
}
//  flex
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

// tranform
.t-upper {
  text-transform: uppercase !important;
}

//  border-radius
.br-xs {
  border-radius: $border-radius !important;
}

.br-50 {
  border-radius: 50% !important;
}

// width
.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33% !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

// background
.bg-primary {
  background-color: $bg-primary !important;
}

.bg-secondary {
  background-color: $bg-secondary !important;
}

// color
.c-bright {
  color: $color-bright !important;
}

.c-primary {
  color: $color-primary !important;
}

.c-secondary {
  color: $color-secondary !important;
}
.c-warning {
  color: $color-warning !important;
}

// Text align
.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

// Margin
.m-auto {
  margin: 0 auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-05 {
  margin: 0.25rem !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

// Margin  bottom
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-05 {
  margin-bottom: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

// Margin top
.mt-0 {
  margin-top: 0 !important;
}

.mt-05 {
  margin-top: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mb-5 {
  margin-top: 2.5rem !important;
}

.mb-6 {
  margin-top: 3rem !important;
}

// Margin left
.ml-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin-left: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.ml-5 {
  margin-left: 2.5rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

// Margin right
.mr-0 {
  margin-right: 0 !important;
}

.mr-05 {
  margin-right: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.mr-5 {
  margin-right: 2.5rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

// Margin left and right
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

// Margin top and bottom
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-05 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

//Padding
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

// Padding bottom
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

// Padding top
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pb-5 {
  padding-top: 2.5rem !important;
}

.pb-6 {
  padding-top: 3rem !important;
}

// Padding left
.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.pb-5 {
  padding-left: 2.5rem !important;
}

.pb-6 {
  padding-left: 3rem !important;
}

// Padding right
.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pr-3 {
  padding-right: 1.5rem !important;
}

.pr-4 {
  padding-right: 2rem !important;
}

.pb-5 {
  padding-right: 2.5rem !important;
}

.pb-6 {
  padding-right: 3rem !important;
}

// Padding left and right
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

// Padding top and bottom
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
