@import 'styles/variables';

.common {
  table {
    margin: 0;
    border: none;
    border-spacing: 0;
    // table-layout: fixed;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
  }

  thead {
    z-index: 1;

    th {
      padding: 0.625rem;
      color: $color-bright;
      text-transform: uppercase;
      font-size: 0.9375rem;
    }
  }

  tbody tr {
    color: $color-secondary;
    padding: 0.625rem 0;

    &:hover {
      background: $bg-primary;
    }

    td {
      padding: 0.31rem 0.625rem;
      color: $color-secondary;
      font-size: 0.9375rem;
      cursor: pointer;
    }
  }

  :global {
    .havePopup {
      width: 1rem !important;
    }
  }
}

.error {
  background-color: $bg-error;
  border: 2px solid $bg-secondary;
}

.tbWrapper {
  background: $bg-secondary;
  overflow-x: auto;
}
.bordered {
  @extend .common;

  tbody tr {
    td {
      border: 1px solid $border-color;
    }
  }

  thead {
    background: $bg-primary;

    th {
      border: 1px solid $border-color;
    }
  }
  .removeOnlyRight {
    border-right: unset;
  }

  .removeOnlyLeft {
    border-left: unset;
  }

  .greenResault {
    background-color: $green;
    color: $white;
  }
}

.list {
  @extend .common;

  tbody tr {
    td {
      border-bottom: 1px solid $border-color;
      padding: 0 0.625rem 0.625rem 0.625rem;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      padding-bottom: 3%;
    }
  }

  .removeOnlyRight {
    border-right: unset;
  }

  .removeOnlyLeft {
    border-left: unset;
  }

  .greenResault {
    background-color: $green;
    color: $white;
  }

  thead {
    background: $bg-secondary;

    th {
      border-bottom: 1px solid $border-color;
      padding: 0 0.625rem 0.625rem 0.625rem;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      color: $darkGray;
      padding-bottom: 3%;
    }
  }
}
