@import 'styles/variables';

.wrapper {
  .box {
   // max-height: 400px;
   // background: $bg-primary;
    overflow-y: auto;
  }

  .item {
    padding: 0.5rem;
    margin-bottom: 1rem;
    background: $bg-primary;
    margin-bottom: 1rem;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    span {
      color: $color-bright;
      font-weight: bold;
    }

    b {
      color: $color-bright;
      display: block;
      text-align: right;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;

    span,
    small {
      font-size: 1rem;
    }
  }

  .message {
    padding: 0.5rem 0;
  }

  .write {
   button{
    margin-bottom: 1rem;
   }
    @media only screen and (min-width: 992px) {
      button{
        margin-bottom: 0;
       }
      display: flex;
      align-items: center;
      :global {
        .g-textarea {
          max-width: 800px;
          width: 80%;
          margin-right: 1rem;
        }
      }
  
      label {
        font-weight: bold;
        color: $color-warning
      }
    }

  }
}