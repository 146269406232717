@import 'styles/variables';

.defaultMulti {
  .fields {
    border: 1px solid $border-color;
    padding: 1rem;
    // margin: 1rem 0;
    border-radius: $border-radius;
    position: relative;
    border: 1px solid $border-color;
    padding: 1rem 0.7rem;
    border-radius: $border-radius;
    background-color: $lightGray0;

    :global {
      .g-select,
      .g-input,
      .g-picker,
      .g-phone-input,
      .g-multi-picker {
        label {
          display: none !important;
        }
      }
      .g-checkbox {
        // label{
        //   display:flex;
        //   flex-direction: column-reverse;
        // }
        align-items: center;
        b {
          display: none !important;
        }
        label {
          margin-top: 0.5rem;
        }
        label span {
          display: none !important;
        }
      }
    }
  }

  .field {
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }

  h3 {
    margin-bottom: 1rem;
    color: $color-bright;
    border-bottom: 1px solid $border-color;
  }
  .firstField {
    :global {
      .g-select,
      .g-input,
      .g-picker,
      .g-phone-input,
      .g-multi-picker {
        label {
          display: block !important;
        }
      }
      .g-checkbox {
        // margin-top: 1.75rem;
        b {
          font-weight: normal;
          display: block !important;
          //  padding-bottom: 0.5rem;
          //  padding-bottom: 1rem;
        }
        label span {
          display: none !important;
        }
      }
    }
    .trash {
      top: 29px;
    }
  }
  .trash {
    position: absolute;
    right: -5px;
    cursor: pointer;
    top: 8px;
  }

  .inputs {
    width: 100%;
    // margin-bottom: 1rem;
  }
  .item {
    padding-right: 1rem;
  }

  .addIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    span {
      margin-left: 1rem;
    }

    svg {
      font-size: 24px;
      padding: 0.2rem;
      box-sizing: border-box;
      color: $bg-secondary;
      background: $bg-bright;
      font-weight: bold;
      border-radius: $border-radius;
    }
  }
}

.multiFieldsRow {
  @extend .defaultMulti;

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    .inputs {
      flex-direction: row;

      > div {
        width: 25%;
      }
    }
  }
}

.multiTwoFields {
  @extend .defaultMulti;

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item {
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    .inputs {
      flex-direction: row;

      .item {
        width: 50%;
      }
    }
  }
}
.multiThreeFields {
  @extend .defaultMulti;

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item {
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    .inputs {
      flex-direction: row;

      .item {
        width: 33%;
      }
    }
  }
}

.multiFourEl {
  @extend .defaultMulti;

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item {
      width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    .inputs {
      flex-direction: row;
      b {
        font-size: 11px;
       // white-space: nowrap;
      }
      :global {
        
        .select {
          width: 25%;
        }
        .input {
          width: 15%;
        }
        .checkbox {
          width: 30%;
        }
      }
    }
  }
}
