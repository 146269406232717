@import 'styles/variables';

.defaultCodeInput {
  position: relative;

  label {
    font-size: $input-label-size;
    color: $input-label;
    display: block;
    padding-bottom: 0.31rem;
  }

  .inputWrapper {
    position: relative;

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}
