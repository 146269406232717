@import 'styles/variables';

.clientForm {
  .entityType {
    margin: 1rem 0;
    padding: 1.5rem 1rem;
    border: 1px solid $border-color-success;
    border-radius: $border-radius;
    background-color: $bg-success;

    label {
      text-transform: uppercase;
      font-weight: bold;
      color: black;
    }

    @media only screen and (min-width: 992px) {
      max-width: 500px;
      max-width: 49%;
      width: 100%;
    }
  }

  .topForm {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 250px;
    }
  }
}

.goBack {
  padding-bottom: 10px;
  b {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    cursor: pointer;
  }
}

.btnGroups {
  display: flex;
  justify-content: flex-end;

  button {
    min-width: 250px;
  }
}

.formHeader {
  .basicInfo {
    display: flex;
    flex-wrap: wrap;

    .item {
      // min-width: 200px;
      // width: 100%;
      flex-grow: 1;
      padding: 0.5rem 0.7rem;
      margin-right: 0.5rem;
      box-sizing: border-box;
      border: 1px solid $border-color-success;
      border-radius: $border-radius;
      margin-bottom: 1rem;

      span {
        font-size: 1.5rem;
      }

      h3 {
        color: $color-bright;
        font-size: 1rem;
      }

      &.warrning {
        background: $bg-error;
        border: 1px solid $border-color-error;

        h3 {
          color: $color-error;
        }
      }
    }
    .duration {
      display: flex;
      flex-direction: column;
      span {
        font-size: 1rem;
      }
    }
    .subActivity {
      display: flex;
      flex-direction: column;
      span:first-child {
        font-weight: bold;
      }
      span:last-child {
        font-size: 1rem;
      }
    }
  }
}
