@import 'styles/variables';
.wrapper {
  position: relative;
  .loaderContainer {
    height: 100%;
    width: 100%;
    background-color: rgba($white, 0.8);
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 99;
  }
  .tbTitle {
    color: $color-warning;
    margin: 0.5rem 0;
  }
}
.loadBlock {
  position: relative;
}
.tables {
  margin-top: 0.5rem;
  h4 {
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: $bg-success;
  }
  table {
    th,
    tr td {
      font-size: 0.9rem;
    }
  }

  @media only screen and (min-width: 992px) {
    display: flex;
    border: 1px solid $border-color;
    > div {
      width: 50%;
    }
    > div:first-child {
      border-right: 1px solid $border-color;
    }
  }
}
