@mixin changeScrollColor($scrollCollotr, $trackCollor, $scrollWidth: 0.25rem, $scrollHeight: 0.75rem) {
  /* Track */
  &::-webkit-scrollbar-track {
    background: $trackCollor;
    border-radius: 2.5rem;
  }
  /* width */
  &::-webkit-scrollbar {
    width: $scrollWidth;
    height: $scrollHeight;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollCollotr;
    border-radius: 2.5rem;
  }
}
