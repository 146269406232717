@import 'styles/variables.scss';
.wrapper {
  .filterRow {
    border: 1px solid $border-color;
    padding: 0.5rem;
  }
  .loaderContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($white, 0.8);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 992px) {
    .firstRow {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1rem;
      > div {
        width: 49%;
      }
      .productDesc {
        max-height: 300px;
        overflow: auto;
        margin-bottom: 1rem;
        border: 1px solid $border-color;
        padding: 0.5rem;
      }
    }
    .btns {
      margin: 1rem 0;
      button {
        margin-right: 1rem;
      }
    }
  }
}
