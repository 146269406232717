@import 'styles/mixins';
.public-wrapper {
  padding: 16px;
  margin: 60px auto 0 auto;
  height: 100%;
  width: 100%;
}

.container-fluid-wrapper {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}

.auth-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  aside {
    background: $bg-secondary;
    margin-bottom: 1rem;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 3;
  }

  section {
    flex-grow: 1;
    background: $bg-secondary;
    padding: 1rem;
    margin: 4rem 1rem;
    border-radius: $border-radius;
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .public-wrapper {
    padding: 2rem;
  }

  .auth-wrapper {
    section {
      margin: 4rem 2rem 2rem 2rem;
      height: 100%;
      width: auto;
      background: $bg-secondary;
    }
  }
}

@media screen and (min-width: 992px) {
  .public-wrapper {
    padding: 2rem 0;
    max-width: 1160px;
  }

  .auth-wrapper {
    display: grid;
    min-height: calc(100% - 60px);
    height: 100%;
    grid-template-columns: 13rem auto;
    align-items: flex-start;
    section {
      margin: 16px;
      background: $bg-secondary;
      min-height: calc(100vh - 92px);
      height: calc(100% - 92px);
      > div {
        min-height: calc(100vh - 124px);
        display: flex;
        flex-direction: column;
      }
    }

    aside {
      position: relative;
      width: 13rem;
      background: $bg-secondary;
      padding: 0;
      margin-bottom: 0;
      //  position: fixed;
      top: 0;
      height: 100%;
      border-right: 1px solid $border-color;
      z-index: 2;
      overflow: auto;
      min-height: calc(100vh - 60px);
      @include changeScrollColor(lighten($mediumGray, 5%), lighten($border-color, 1.5%), 0.5rem);
    }
  }
}
