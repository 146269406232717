@import 'styles/variables';

.search {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  margin-bottom: 1rem;

  .searchBasic {
    max-width: 600px;
    width: 100%;
    display: flex;
    position: relative;

    >div:first-child {
      width: 100%;
    }

    >div:last-child>div {
      width: 20px;
      right: 5px;
      top: 5px
    }
    input::placeholder {
      color: #6f6f6f!important;
      font-size: 0.8rem;
    }
  }

  .searchAdanced {
    position: relative;
  }
}