@import 'styles/variables';

.wrapper {
  background: $bg-secondary;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid $border-color;

  .content {
    max-width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  img {
    height: 1.875rem;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    li {
      padding-left: 1.5rem;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  :global {
    .active {
      color: $link-hover;
    }
  }

  h3 {
    margin: 0;
  }

  .userBox {
    position: relative;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    width: 200px;
    background: $bg-secondary;
    border: 1px solid $border-color;
    padding: 0.62rem;
    box-sizing: border-box;
    border-radius: $border-radius;
    align-items: center;
    z-index: 2;

    ul {
      display: flex;
      flex-direction: column;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      li {
        width: 100%;
        padding: 0.31rem 0;
        border-bottom: 1px solid $border-color;
        align-items: flex-start;
        display: flex;

        a {
          margin-left: 0.31rem ;
        }
      }
    }

    // svg {
    //   color: $bg-primary;
    // }
  }

  .user {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.62rem ;
      font-size: 1.5rem;
    }

    :global {
      .fa-circle-user {
        color: $bg-light;
      }

    }

    .visible {
      display: none;

      svg {
        margin-left: 0;
        font-size: 1rem;
      }
    }

    &:hover {
      .visible {
        display: flex;
        position: absolute;
        right: 0;
        top: 3rem;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 2rem;
  }

  @media screen and (min-width: 1200px) {
    // padding: 0;
    // .content{
    //   max-width: 1160px;
    // }
  }
}