@import 'styles/variables';
.wrapper {
  h3 {
    font-size: 1rem;
    font-weight: normal;
    padding: 0.5rem 0;
  }
}
.warning {
  padding: 0rem 0.5rem;
  border-radius: 5px;
  color: $color-error;
  background: $bg-error;
}
