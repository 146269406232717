* {
  box-sizing: border-box;
}

body,
html {
  font-family: $font-primary;
  height: 100%;
  margin: 0;
  color: $color-primary;
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 14px;
  background: $bg-primary;
}
#root,main,.main-content {
  min-height: 100%;
 // height: auto;
}
a {
  color: $link;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  :active {
    color: $link-hover;
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}

input:focus,
textarea:focus {
  outline: none;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}
