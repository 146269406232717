@import 'styles/variables';

.wrapper {
  svg {
    cursor: pointer;
    width: 1rem;
    font-size: 0.8rem;
  }

  .hidden {
    display: none;
  }

  .visible {
    display: flex;
    position: absolute;
    background-color: $bg-secondary;
    border: 1px solid $border-color;
    padding: 0.75rem;
    border-radius: $border-radius;
    right: 0;
    flex-direction: column;
    z-index: 1;
    width: 130px;

    .action {
      display: flex;
      margin-bottom: 0.625rem;
      cursor: pointer;

      span {
        margin-left: 0.3rem;
        font-size: 0.875rem;
        color: $color-primary;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {

        span,
        svg {
          color: $color-bright;
        }
      }
    }
  }

  .btnGroup {
    display: flex;
  }

  .icon {
    padding: 3px;
    border-radius: 0.31rem;
    margin-right: 0.31rem;
    cursor: pointer;
    &:hover {
      color: $color-primary;
    }
  }
}