@import 'styles/variables';

.wrapper {
  label,
  .label {
    width: 200px;
    display: flex;
  }

  .rowCheck {
    display: flex;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
  }

  h4 {
    color: $color-primary;
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-weight: normal;
    border: 1px solid $border-color;
    background: $bg-primary;
  }

  .info {
    margin: 0.5rem 0rem 1rem 0rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    h3 {
      margin-bottom: 0.5rem;
      //font-weight: normal;
    }

    span {
      font-size: 1rem;
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      font-weight: bold;
    }

    .supply {
      background-color: $bg-secondary;
      color: $color-warning;
      border: 1px solid $color-warning;
    }

    .demand {
      background-color: $bg-secondary;
      color: $color-bright;
      border: 1px solid $color-bright;
    }
  }
}

.customSelect {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 1rem;
    color: $input-text;
  }
}

.customOption {
  padding: 0.2rem 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: $bg-primary;
  }
}
.group {
  padding: 0.5rem;
  background: $bg-success;
  margin-bottom: 1rem;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    width: 49%;
  }
}
.price {
  > div {
    width: 31%;
  }
  :global {
    .Valuta {
      margin-left: 10px;
    }
  }
}
.between {
  display: none;
}
@media only screen and (min-width: 992px) {
  .between {
    width: 20px !important;
    display: flex;
    align-items: center;
    justify-self: center;
    font-size: 25px;
    color: $color-secondary;
    box-sizing: border-box;
    padding: 3px;
    position: relative;
    top: 3px;
    margin-top: 1rem;
    left: 2px;
  }
  .group {
    padding: 0.5rem;
    background: $bg-success;
    margin-left: 200px;
  }
}
