@import 'styles/variables';

.defaultInput {
  position: relative;
  margin-bottom:$input-margin-bottom;
  label {
    font-size: $input-label-size;
    color: $input-label;
    display: block;
    padding-bottom: 0.31rem;
  }

  .input {
    padding: 0 0.625rem;
    height: $input-min-height;
    border: 1px solid $input-border;
    border-radius: $border-radius;
    font-size: 1rem;
    font-weight: 400;
    color: $input-text;
    background-color: $input-bg;
    background-clip: padding-box;
    display: block;
    width: 100%;
    // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: $input-placeholder;
      font-size: 0.8rem;
      font-family: $font-primary;
    }

    &[type='password'] {
      letter-spacing: 0.063rem;
      font-weight: bold;
      //font-size: 1.5rem;
    }

  }

  .inputWrapper {
    position: relative;

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}