@import 'styles/variables';

.wysiwygDefault {
  :global {
    .quill {
      .ql-toolbar {
        border-top-left-radius: 0.31rem;
        border-top-right-radius: 0.31rem;
        border: 1px solid $border-color;
      }
      .ql-container {
        border-bottom-left-radius: 0.31rem;
        border-bottom-right-radius: 0.31rem;
        border: 1px solid $border-color;
        background-color: $bg-secondary;
        min-height: 200px;
      }

      .ql-editor.ql-blank::before {
        color: $input-placeholder;
        font-size: 0.8rem;
      }
    }
  }

  label {
    font-size: $input-label-size;
    color: $input-label;
    display: block;
    padding-bottom: 0.31rem;
  }
}
