@import 'styles/variables';
.wrapper {
  h2 {
    border-bottom: 1px solid $border-color;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
  a {
    text-decoration: underline;
    color: $color-bright;
  }
  table {
    width: 100%;
    border-spacing: 0;
    td,
    th {
      border-bottom: 1px solid $border-color;
      padding: 0.5rem;
      text-align: left;
      font-size: 0.9rem;
    }
  }
  a svg {
    color: $color-bright!important;
    font-size: 0.7rem!important;
    padding-left: 5px;
  }
  small {
    padding: 0 0.3rem;
    font-size: 0.9rem;
  }
  .newValue {
    color: $color-bright;
    svg{
      color: $color-bright!important;
    }

  }
  .oldValue {
  }
  .deletedValue {
    color: $color-error;
  }
  .created {
    svg {
      font-size: 1rem;
      color: $color-warning;
      margin-right: 0.3rem;
    }
  }
  .deleted {
    svg {
      font-size: 0.9rem;
      color: $color-error;
      margin-right: 0.3rem;
    }
  }
  .changes {
    margin-bottom: 0.3rem;
    svg {
      font-size: 0.9rem;
      color: $color-success;
      margin-right: 0.3rem;
    }
  }
  .korisnikId {
    width: 20%;
  }
  .promena {
    width: auto;
  }
  .createdAt {
    width: 150px;
  }
}
