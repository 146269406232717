@import 'styles/variables';
@import 'styles/mixins';

.link {
  color: $color-primary;
  // font-weight: 500;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom: 1px solid $border-color;
}

.wrapper {
  .toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    height: 2rem;
    background-color: $bg-bright;

    svg {
      font-size: 1.5rem;
      color: $color-light;
      cursor: pointer;
    }
  }

  .toggleHidden {
    display: none;
  }

  .list {
    display: block;
    width: 100%;
    background: $bg-success;
  }
  .lastChilde {
    padding-bottom: 100px;
  }
  .listHidden {
    display: none;
  }

  :global {
    .active {
      color: $color-bright !important;
    }
  }

  .noChildren {
    a {
      @extend .link;
      padding: 12px 8px;

      svg {
        font-size: 1rem;
        margin-right: 0.31rem;
      }

      &:hover {
        color: $color-bright;
        background-color: $bg-primary;
      }
    }
  }

  .haveChildren {
    ul {
      display: none;
    }

    &.activeParent ul {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $border-color;
      padding: 0.31rem 0;
      max-height: 410px;
      overflow-y: auto;
      @include changeScrollColor(lighten($mediumGray, 5%), lighten($border-color, 1.5%), 0.25rem);
      a {
        @extend .link;
        padding: 0.31rem 0.31rem 0.31rem 1.875rem;
        font-size: 0.9375rem;
        border-bottom: none;
        color: $color-secondary;

        &:hover {
          color: $color-bright;
        }
      }
    }
  }

  .parent {
    color: $color-primary;
    // font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid $border-color;

    span {
      svg {
        font-size: 1rem;
        margin-right: 0.31rem;
      }
    }

    &:hover {
      color: $color-bright;
      background-color: $bg-primary;
    }
  }

  svg {
    font-size: 0.62rem;
  }

  @media screen and (min-width: 992px) {
    .list {
      background: initial;
    }
  }
}
