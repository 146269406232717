@import 'styles/variables';

.defaultRadio {
  label {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0;
    cursor: pointer;
    font-size: $input-label-size;
    color: $input-label;
    user-select: none;
    min-height: 20px;
    display: flex;
    align-items: center;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .label {
      padding-left: 5px;
    }

    .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: $input-bg;
      border-radius: 50%;
      border: 1px solid $input-border;
    }

    &:hover input~.checkmark {
      background-color: $input-bg;
    }

    input:checked~.checkmark {
      border: 1px solid $bg-bright;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark:after {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $bg-bright
    }

  }
}