@import 'styles/variables';

.verticalTabs {
  display: flex;
  flex-direction: column;
  // background-color: $bg-success;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  height: 100%;
  min-height: 800px;

  .tab {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0.5rem;
    border-bottom: 1px solid $border-color;
    // background: $bg-success;
    cursor: pointer;

    text-align: center;
    font-size: 0.9rem;
   

    &.active {
      // background: $bg-bright;
      color: $greenDark;
      font-weight: bold;
    }
  }
}

.horizontalTabs {
  display: flex;
  flex-direction: column;
  // background-color: $bg-success;
  // padding: 0.4rem;
  border-bottom: 1px solid $border-color;

  .tab {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.5rem;
    cursor: pointer;

    text-align: center;
    font-size: 0.9rem;

    &.active {
     // background: $bg-bright;
     color: $greenDark;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: wrap;

    .tab {
      margin: 0.2rem;
    }
  }
}