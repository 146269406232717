@import 'styles/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  display: grid;
  place-items: center;
  overflow: visible;
  width: 150px;
  height: 150px;
}

.pie {
  display: grid;
  place-items: center;
  overflow: visible;
}
