@import 'styles/variables';
.wrapper {
  &.defaultFormHeader {
    // border-radius: $border-radius;

    h2 {
      font-size: 1rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.625rem;
      border-bottom: 1px solid $border-color;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      small {
        color: $color-warning;
        //  font-weight: normal;
        padding-left: 0.31rem;
        font-size: inherit;
      }
    }
    .component {
      h6 {
        font-size: 1rem;
        color: $color-warning;
        cursor: pointer;
        text-decoration: underline;
        text-transform: initial;
      }
    }
  }
  .btnsGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $border-color;
  }
}
