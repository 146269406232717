@import 'styles/variables.scss';

.wrapper {
  .loaderContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($white, 0.8);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttonPosition {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .marginRight {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}
