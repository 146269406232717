$white: #ffffff;
$black: #000000;
$green: #30b457;
$lightGray: #E5E5E5;
$lightGray0: #fcfcfc;
//$lightGray1: #f6f6f6;
$lightGray1: #f9f9f9;
$mediumGray: #808080;
$darkGray: #4D4D4D;
$darkGray2: #282828;
$red: #ff7f7f;
$redDark: #e73636;
$redLight: #ffdbd8;
$yellowLight: #ffe599;
$orange: #ff9000;
$greenLight:#30b4571f;
$greenLight1:#30b45770;
$greenDark:#148936;
$blueLight:#deebf7;


$bg-primary: $lightGray1;
$bg-secondary: $white;
$bg-bright: $green;
$bg-light: $lightGray;
$bg-success: $greenLight;
$bg-error: $redLight;
$bg-info: $blueLight;


$color-primary: $darkGray2;
$color-secondary: $mediumGray;
$color-bright: $green;
$color-light: $white;
$color-warning: $orange;
$color-success:$greenDark;
$color-error:$redDark;

$border-color: #e4e4e4;
$border-color-success: $greenLight1;
$border-color-error:$red;
$border-radius: 0.31rem;

$link: $darkGray;
$link-hover: $green;

$btn-primary-bg: $green;
$btn-primary-color: $white;
$btn-primary-border: $green;
$btn-primary-hover: #00902b;
$btn-primary-hover-color: $white;
$btn-secondary-bg: $white;
$btn-secondary-color: $darkGray2;
$btn-secondary-border: $green;
$btn-secondary-hover: $green;
$btn-secondary-hover-color: $white;
$btn-warn-bg: $orange;
$btn-warn-color: $white;
$btn-warn-border: $orange;
$btn-warn-hover: #f77c0c;
$btn-warn-hover-color: $white;

$input-text: $darkGray;
$input-bg: $white;
$input-label: $mediumGray;
$input-border: $lightGray;
$input-error:$redDark;
$input-placeholder: #d1d1d1;
$input-label-size: 0.875rem;
$input-margin-bottom: 0.875rem;
$input-min-height: 30px;

$font-primary: 'Roboto';
$font-secondary: 'Arial-Black';

// CHART COLORS

:export {
    white: $white;
    black: $black;
    green: $green;
    greenLight: $greenLight;
    lightGray: $lightGray;
    lightGray1: $lightGray1;
    mediumGray: $mediumGray;
    darkGray: $darkGray;
    darkGray2: $darkGray2;
    red: $red;
    redLight: $redLight;
}