@import 'styles/variables.scss';

.customSelect {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: $bg-primary;
  }

  span {
    font-size: 1rem;
    color: $input-text;
  }
}

.customCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.table{
  :global{
   thead th.btnActions{
      width: 60px!important;
    }
  }
}