@import 'styles/variables';

.wrapper {
  table {
    border-spacing: 0;
    width: 100%;
    background-color: $bg-primary;
    td,
    th {
      border-bottom: 1px solid $border-color;
      padding: 0.5rem;
    }

    tr:hover {
      background: $bg-primary;
    }
  }

  .firstColumn {
    text-align: left;
  }

  @media only screen and (min-width: 992px) {
    .body {
      width: 50%;
    }

    input {
      width: 50% !important;
    }

    .otherColumn {
      > div {
        align-items: center;
      }
    }
  }
}
