.blueTable {
  border-top: 1px solid #1c6ea4;
  border-bottom: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  td,
  th {
    padding: 10px 0px;
  }

  tbody td {
    font-size: 13px;
    padding-left: 10px;
  }
  .bold {
    font-weight: 700;
  }

  thead {
    background: #1c6ea4;
    background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
    background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
    background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
    border-bottom: 0px solid #444444;
  }

  thead th {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    border-left: 0px solid #d0e4f5;
  }

  thead th:first-child {
    border-left: none;
  }

  tfoot td {
    font-size: 14px;
  }

  tfoot .links {
    text-align: right;
  }

  tfoot .links a {
    display: inline-block;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 5px;
  }
  .borderTop {
    border-top: 1px solid black;
  }
  .borderBottom {
    border-bottom: 1px solid black;
  }
}
