@import 'styles/variables';

.wrapper {
  // margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $border-color;

  h1 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      display: flex;
      align-items: center;

      b {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        cursor: pointer;

        a {
          font-size: 1.1rem;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }

  p {
    font-size: 1rem;
    color: $color-secondary;
  }

  .total {
    font-weight: normal;
    font-size: 1rem;
    background: $bg-primary;
    padding: 0.3rem 0.5rem;
    font-weight: bold;
    color: $color-bright;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .children {
    margin-top: 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      margin: 0 1rem;
      width: 46%;
    }
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      width: 50%;
    }

    // button {
    //   padding-left: 1rem;
    //   min-width: 5rem;
    // }
    .children {
      margin-top: 0;
      width: 50%;

      button {
        padding-left: 1rem;
        min-width: 6rem;
        width: auto;
        margin: 0 0 0 1rem;
      }
    }
  }
}

.defaultTitle {
  @extend.wrapper;
}

.greenTitle {
  @extend.wrapper;
  background-color: $bg-bright;
  color: $color-light;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: $border-radius;
  h1 {
    text-transform: uppercase;
   //font-weight: normal;
    font-size: 1.7rem;
  }
  h5 b {
    color: #03521a;
  }
  svg {
    color: $color-light;
  }
}
