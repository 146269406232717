.history-link {
  text-decoration: underline;
  padding: 0.5rem 0;
  color: $color-warning;
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}
.history-link-border {
  @extend .history-link;
  border-bottom: 1px solid $border-color;
}

.customSelect {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 1rem;
    color: $input-text;
  }
}

.customOption {
  padding: 8px 12px;
  cursor: pointer;
  span {
    font-size: 1rem;
    color: $input-text;
  }

  &:hover {
    background-color: $bg-light;
    span {
      color: $bg-bright;
    }
  }
}