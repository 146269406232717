@import 'styles/variables';
@import 'styles/mixins';

.expandIcon {
  font-size: 1rem;
}

.accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 4px;
}

.accordionHeader {
  font-size: 1rem;
  border: 1px solid $border-color;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  // background-color: greenyellow;
  svg {
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }
  label {
    color: $color-bright !important;
    font-weight: bold !important;
  }
}
.firstLevel {
  border: 1px solid $border-color;
  margin-bottom: 0.5rem;
  padding: 0 !important;
  > .accordionHeader {
    border: none;
    border-bottom: 1px solid $border-color;
  }
  &.closed {
    .accordionHeader {
      border-bottom: none;
    }
  }
}
.nestedFirstLevel {
  &.checkboxList {
    border-right: none;
    border-left: none;
  }
}
.nestedLevels {
}
.levels {
  .checkboxList {
    border: 1px solid $border-color;
    border-right: none;
  }
  > .accordionHeader {
    border-bottom: none;
    border-right: none;
  }
  &.closed {
    .accordionHeader {
      border-bottom: 1px solid $border-color;
    }
  }
}

.accordionItem {
  font-size: 1rem;
}

.accordionContent {
  width: 100%;
}

.checkboxList {
  width: 100%;
  order: 1;
  background-color: $bg-primary;
  padding: 0.5rem;
  padding-left: 33px;
  border: 1px solid $border-color;
  border-top: 0;

  label {
    margin-right: 60px !important;
  }
}

.accordionLevel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;

  > .accordionHeader {
    width: 100%;
  }
}

svg {
  font-size: 1rem;
}

.nestedCheckboxes {
  .checkBox {
    margin-right: 60px;
  }
}
