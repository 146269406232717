@import 'styles/variables';

.importWrapper {
  .file {
    visibility: hidden;
    width: 0;
  }

  .importedFile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    grid-gap: 1rem 0;
  }

  .swichFile {
    background: $bg-primary;
    padding: 1rem;
    border-radius: $border-radius;
    position: relative;
    height: 9rem;
    border: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      display: flex;
      font-size: 5rem;
      color: $color-primary;
      cursor: pointer;
    }
    .removeSvg {
      svg {
        position: absolute;
        right: -5px;
        top: -5px;
        font-size: 1.7rem;
        color: $color-primary;
        cursor: pointer;
      }
    }
  }
}
