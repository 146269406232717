@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  flex-wrap: wrap;
  .loader {
    border: 5px solid $bg-primary;
    border-top: 5px solid $color-bright;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 2s linear infinite;
  }
  h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
