@import 'styles/variables';
.slateWrapper {
  // max-width:800px;
  // box-sizing: border-box;
  //border: 1px solid;
  // padding: 2%;
  // margin: 0 auto;
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}
.print{
  max-width:800px;
  box-sizing: border-box;
  padding: 2%;
  margin: 0 auto;
}
.border{
  border: 1px solid $border-color;
}
.defaultTable {
  width: 100%;
  border-collapse: collapse;
  table,
  th,
  td {
    border: 1px solid $color-primary;
    padding: 5px;
  }
}

.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}

.headerTable {
  width: 100%;
  display: inline-block;
  border: 1px solid;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 20px;
  tbody {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  tr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.headerTableTrImg {
  width: 200px;
  justify-content: center;
}
.headerTableTrFirst {
  width: 40%;
}
.headerTableTrSecond {
  width: 200px;
}
.headerTbImg {
  display: inline-block;
  width: 100%;
}

.tableClient {
  width: 100%;
  display: inline-block;
  border: 1px solid;
  font-size: 12px;
  position: relative;
  top: 0;
  tbody {
    display: flex;
    justify-content: space-between;
  }
}

.clientTrFirst {
  display: flex;
  width: 50%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
.clientTrSecond {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 10px;
  border-left: 1px solid;
}
.clientLabel {
  font-size: 20px;
  display: flex;
  flex-direction: column;
}
.clientValue {
  font-size: 20px;
  padding: 10px;
  border: 1px solid;
  min-width: 40%;
  text-align: right;
}
.headingH3 {
  font-weight: normal;
}

.spaceAround {
  padding: 10px 0;
}
.signitureLabel {
  border-top: 1px solid;
  width: 200px;
  display: flex;
  margin-left: auto;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}
.signitureLetter {
  margin-top: 200px;
  font-size: 16px;
  text-align: center;
}


// @media print {
//   body {
//     visibility: hidden;
//   }
//   #printContainer {
//     visibility: visible;
//     background: red;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }
