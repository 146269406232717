@import 'styles/variables';

.groupFeturesMultifield {
  border: 1px solid $border-color;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: $border-radius;
  label span {
    font-weight: bold;
    color: $input-text;
  }
}
.stylesGroupMutiTitle {
  color: $input-label;
  font-size: 0.875rem;
  font-weight: normal;
  margin: 1rem 0;
}
