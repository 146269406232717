@import 'styles/variables';
.wrapper{
    background-color: $bg-secondary;
    height: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: $color-bright;
    width: 85%;
    margin: 5rem auto;
    padding: 2rem;
    font-size: 1.3em;

}