@import 'styles/variables';
@import 'styles/mixins';
.wrapper {
  .basicInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    .leftBlock {
      font-size: 1.2rem;
      padding: 0.5rem;
      border-right: 1px solid $border-color;
      flex-grow: 1;

      h1 {
        color: $color-bright;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $border-color;;
      }

      h5 {
        font-weight: normal;
        border-bottom: 1px solid $border-color;
        padding: 0.3rem 0;
        b {
          margin-right: 0.5rem;
        }
        &:last-child{
          border-bottom: none
        }
      }
   
    }
    .middleBlock {
      border-right: 1px solid $border-color;
      padding: 0.5rem;
      font-size: 1.2rem;

      h1 {
        color: $color-bright;
      }

      h5 {
        font-weight: normal;
        border-bottom: 1px solid $border-color;
        padding: 0.3rem 0;
        b {
          margin-right: 0.5rem;
        }
      }
      p {
        font-size: 1rem;
        //opacity: 0.8;
        padding: 0.5rem 0;
        color: $color-warning;
        max-height: 70px;
        overflow: auto;
        @include changeScrollColor(lighten($mediumGray, 5%), lighten($border-color, 1.5%), 0.5rem);
      }
      @media screen and (min-width: 992px) {
        width: 40%;
      }
    }
    .rightBlock {
      font-size: 1.4rem;
      padding: 0.5rem;
      display: flex;

      align-items: center;

      .schedule {
        display: flex;
        justify-content: center;
        background: #ff900021;
        padding: 1rem;
        border-radius: 5px;
        height: 100%;
        align-items: center;

        button {
          width: 250px;
        }
      }
    }

    @media screen and (min-width: 992px) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .searchContainer {
    display: flex;
    flex-direction: column;
  }

  .searchTable {
    padding: 0;
    margin-bottom: 1rem;
  }

  .body {
    display: flex;
    align-items: flex-start;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-top: 0.5rem;
    // min-height: 800px;
    height: 100%;
    background: $bg-secondary;
    .first {
      // width: 170px;
    }

    .second {
      width: calc(100% - 170px);
      padding: 0.7rem;
      background-color: $bg-primary;

      > div:first-of-type {
        background-color: $bg-secondary;
        padding: 0.5rem;
        border-radius: $border-radius;
      }
    }

    .tabTitle {
      text-transform: uppercase;
      margin-bottom: 1rem;
      border-bottom: 1px solid $border-color;
    }
  }
}

.subTabsBody {
  margin-top: 1rem;

  :global {
    .th_actions {
      width: 20px !important;
      padding: 0 !important;
    }
  }

  table svg {
    color: $color-warning;
    width: 25px;
    height: 25px;
  }
}

.formHeader {
  .basic {
    display: flex;
    flex-wrap: wrap;

    .item {
      // min-width: 200px;
      // width: 100%;
      flex-grow: 1;
      padding: 0.5rem 0.7rem;
      margin-right: 0.5rem;
      box-sizing: border-box;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      margin-bottom: 1rem;

      span {
        font-size: 1.1rem;
      }

      h3 {
        color: $color-bright;
        font-size: 1rem;
        margin-bottom: 0.3rem;
        text-transform: uppercase;
      }

      &.warrning {
        background: $bg-error;
        border: 1px solid $border-color-error;

        h3 {
          color: $color-error;
        }
      }
    }

    .duration {
      display: flex;
      flex-direction: column;

      // span {
      //   font-size: 1rem;
      // }
    }

    .subActivity {
      display: flex;
      flex-direction: column;

      span:first-child {
        // font-weight: bold;
      }

      span:last-child {
        font-size: 1rem;
        opacity: 0.7;
      }
    }
  }
}
