@import 'styles/variables';
.wrapper {
  padding: 1rem;
  border: 1px solid $border-color;
  border-radius: 0.31rem;
  background-color: $lightGray0;
  .box {
    margin: 1rem 0;
    thead th {
      padding-top: 0.5rem;
      font-size: 0.9rem;
    }
    tbody td {
      //padding-top: 0.5rem;
      font-size: 1rem;
    }
  }
  :global {
    .tb-custom{
      width: 100%;
    }
  }
}
